.status-product {
  display: block;

  &--in {
    color: $color-silver-tree;
  }

  &--none {
    color: $color-brick-red;
  }
}
