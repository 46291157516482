.custom-list {
  @include list-reset;
  font-size: 13px;
  line-height: 24px;
  list-style-type: disc;
  color: $color-amaranth;

  li {
    padding-left: 5px;

    .custom-list__title {
      font-weight: 600;
    }

    span {
      font-weight: 400;
      color: $color-black;
    }
  }

  @include viewport--sm {
    margin-top: 10px;
    font-size: 21px;
    line-height: 36px;

    li {
      padding-left: 15px;
    }
  }
}
