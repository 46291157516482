@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin viewport--lg {
  @media (max-width: $viewport--xl - 1) {
    @content;
  }
}

@mixin viewport--md {
  @media (max-width: $viewport--lg - 1) {
    @content;
  }
}

@mixin viewport--sm {
  @media (max-width: $viewport--md - 1) {
    @content;
  }
}

@mixin container {
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 20px;
}

@mixin button-reset {
  padding: 0;
  border: none;
  font-family: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}

@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}
