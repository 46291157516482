.not-found__wrapper {
  @include viewport--sm {
    padding-left: 23px;
    padding-right: 23px;
  }
}

.not-found__title {
  margin: 0;
  margin-bottom: 13px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;

  @include viewport--sm {
    margin-bottom: 25px;
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 440px) {
    font-size: 24px;
    line-height: 1;
  }
}

.not-found__text {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;

  @include viewport--sm {
    font-size: 21px;
    line-height: 1;
  }

  @media (max-width: 440px) {
    font-size: 16px;
  }
}
