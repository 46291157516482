.breadcrumbs {
  margin-bottom: 10px;
  padding-top: 17px;

  @include viewport--sm {
    margin-bottom: 25px;
    padding-top: 23px;
    padding-left: 23px;
    padding-right: 23px;
  }
}

.breadcrumbs__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  position: relative;
  margin-right: 12px;
  font-size: 13px;
  line-height: 34px;
  font-weight: 300;

  &:last-child {
    margin-right: 0;

    &::after {
      display: none;
    }
  }

  &::after {
    content: "/";
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-nobel;
  }

  @include viewport--sm {
    margin-right: 25px;
    font-size: 18px;
    line-height: 30px;

    &::after {
      right: -14px;
    }
  }
}

.breadcrumbs__link {
  color: $color-nobel;

  &[href] {
    text-decoration: underline;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-royal-blue;
      }
    }
  }
}
