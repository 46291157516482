.important-info {
  padding: 32px 44px 25px;
  background-color: $color-white-smoke;
  border-left: 4px solid $color-amaranth;

  @include viewport--sm {
    padding-left: 0;
    padding-right: 0;
  }
}
