.head-search {
  position: relative;
  width: 100%;
  background-color: $color-white;
  box-shadow: 0 16px 13px 0 rgba(131, 131, 131, 0.39);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-gainsboro;
  }
}

.head-search__product {
  padding-top: 25px;
}

.head-search__card {
  padding-left: 20px;
  padding-right: 25px;
}

.head-search__total {
  padding: 21px 24px;
}

.head-search__total-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 18px;
  color: $color-matterhorn;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-dodger-blue;

      span {
        color: $color-dodger-blue;
      }

      svg {
        fill: $color-dodger-blue;
      }
    }
  }

  span {
    margin-left: 5px;
    margin-right: 10px;
    font-weight: 700;
    color: $color-black;
    transition: all $transition;
  }

  svg {
    fill: $color-black;
    transition: all $transition;
  }
}

