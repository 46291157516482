.product__wrapper {
  padding-top: 30px;

  @include viewport--sm {
    padding-top: 17px;
    padding-bottom: 90px;
  }

  @media (max-width: 540px) {
    padding-bottom: 45px;
  }
}

.product__top {
  display: grid;
  grid-template-columns: 336px 1fr;
  grid-column-gap: 35px;
  margin-bottom: 68px;

  @media (max-width: 1140px) {
    grid-template-columns: 269px 1fr;
    grid-column-gap: 19px;
  }

  @include viewport--md {
    grid-template-columns: 1fr;
  }

  @include viewport--sm {
    margin-bottom: 103px;
    padding-left: 23px;
    padding-right: 23px;
  }

  @media (max-width: 540px) {
    margin-bottom: 50px;
  }
}

.product__right {
  @media (max-width: 1023px) {
    overflow-x: auto;
    margin-bottom: 20px;
  }

  @include viewport--sm {
    margin-bottom: 46px;
  }

  @media (max-width: 440px) {
    margin-bottom: 30px;
  }
}

.product__img {
  position: relative;
}

.product__gallery-top {
  position: relative;
  margin-bottom: 15px;

  img {
    width: 336px;
    height: 457px;
    object-fit: cover;
  }

  @media (max-width: 1140px) {
    img {
      width: 269px;
      height: 360px;
    }
  }

  @include viewport--sm {
    max-width: 504px;
    margin-right: auto;
    margin-left: auto;

    img {
      width: 504px;
      height: 665px;
    }
  }

  @media (max-width: 540px) {
    max-width: 300px;

    img {
      width: 300px;
      height: 395px;
    }
  }

  @media (max-width: 440px) {
    max-width: 250px;

    img {
      width: 250px;
    }
  }
}

.product__gallery-thumbs {
  max-height: 70px;

  .swiper-button-next,
  .swiper-button-prev {
    top: 43px;
    width: 26px;
    height: 26px;
    background-color: #e5e5e5;
    border-radius: 50%;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-next svg,
  .swiper-button-prev svg {
    fill: $color-black;
  }

  .swiper-button-prev svg {
    transform: rotate(180deg);
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    content: none;
  }

  & .swiper-slide {
    cursor: pointer;
  }

  & img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }

  @media (max-width: 1023px) {
    max-width: 270px;
    margin-right: 0;
    margin-left: 0;
  }

  @include viewport--sm {
    max-width: 504px;
    margin-left: auto;
    margin-right: auto;
  }
}

.product__flags {
  @include viewport--md {
    right: auto;
    left: 200px;
  }

  @include viewport--sm {
    span {
      font-size: 18px;
    }

    .product__flags-bestseller {
      width: 126px;
    }
  }

  @media (max-width: 440px) {
    span {
      font-size: 14px;
    }

    .product__flags-bestseller {
      width: 105px;
    }
  }
}

.product__tags {
  display: flex;
  flex-direction: column;
  margin-top: 22px;

  &--mobile {
    display: none;
  }

  @include viewport--md {
    display: none;
  }

  @include viewport--sm {
    &--mobile {
      display: flex;
      margin-top: 69px;

      .product__tags-link {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }

  @media (max-width: 540px) {
    &--mobile {
      margin-top: 30px;

      .product__tags-link {
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
}

.product__tags-link {
  font-size: 15px;
  line-height: 24px;
  color: $color-havelock-blue;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-brick-red;
    }
  }
}

.product__title {
  margin: 0;
  margin-bottom: 3px;
  line-height: 30px;

  @include viewport--sm {
    margin-bottom: 29px;
    font-size: 36px;
    line-height: 40px;
  }

  @media (max-width: 540px) {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
  }
}

.product__status {
  display: flex;
  align-items: center;
  margin-bottom: 27px;

  svg {
    flex-shrink: 0;
    margin-right: 5px;
    fill: $color-silver-tree;
  }

  @include viewport--sm {
    margin-bottom: 57px;

    svg {
      width: 26px;
      height: 18px;
      margin-right: 9px;
    }
  }

  @media (max-width: 540px) {
    margin-bottom: 25px;

    svg {
      width: 20px;
      height: 12px;
      margin-right: 5px;
    }
  }
}

.product__status-text {
  line-height: 30px;
  font-weight: 600;

  @include viewport--sm {
    font-size: 24px;
  }

  @media (max-width: 540px) {
    font-size: 16px;
  }
}

.product__price-list {
  display: flex;
  align-items: baseline;
  margin-bottom: 13px;

  @include viewport--sm {
    margin-bottom: 35px;
  }

  @media (max-width: 540px) {
    margin-bottom: 25px;
  }
}

.product__price-current {
  margin-right: 15px;
  font-size: 32px;
  line-height: 1;

  @include viewport--sm {
    margin-right: 20px;
    font-size: 60px;
  }

  @media (max-width: 540px) {
    font-size: 32px;
  }
}

.product__price--old {
  font-size: 22px;
  line-height: 1;
  color: $color-dim-gray;
  text-decoration: line-through;

  @include viewport--sm {
    font-size: 32px;
  }

  @media (max-width: 540px) {
    font-size: 22px;
  }
}

.product__order {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 26px;

  @include viewport--sm {
    margin-bottom: 65px;
  }

  @media (max-width: 540px) {
    margin-bottom: 25px;
  }
}

.product__button-order {
  width: 180px;
  margin-right: 24px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;

  &--order {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &--report {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include viewport--sm {
    width: 270px;
    margin-bottom: 20px;
    font-size: 36px;

    &--order {
      padding-top: 20px;
      padding-bottom: 29px;
    }

    &--report {
      padding-top: 13px;
      padding-bottom: 12px;
    }
  }

  @media (max-width: 540px) {
    width: 180px;
    font-size: 18px;

    &--order {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &--report {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.product__delivery-info {
  max-width: 280px;
  margin: 0;
  margin-bottom: 10px;
  line-height: 16px;
  font-weight: 300;
  color: $color-pink-swan;

  @include viewport--sm {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 24px;
  }

  @media (max-width: 540px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.product__features {
  margin-bottom: 34px;

  @include viewport--sm {
    margin-bottom: 80px;
  }

  @media (max-width: 540px) {
    margin-bottom: 30px;
  }
}

.product__feature {
  display: flex;

  @media (max-width: 440px) {
    justify-content: space-between;
  }
}

.product__feature-caption,
.product__feature-info {
  font-size: 15px;
  line-height: 30px;

  @include viewport--sm {
    font-size: 24px;
    line-height: 48px;
  }

  @media (max-width: 540px) {
    font-size: 13px;
    line-height: 30px;
  }
}

.product__feature-caption {
  width: 186px;
  color: $color-grey;

  @media (max-width: 1140px) {
    width: 175px;
  }

  @include viewport--sm {
    width: 254px;
  }

  @media (max-width: 540px) {
    width: 175px;
  }

  @media (max-width: 440px) {
    width: auto;
  }
}

.product__feature-link {
  color: $color-havelock-blue;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-brick-red;
    }
  }
}

.product__text {
  h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 800;
  }

  @include viewport--sm {
    h3 {
      margin-bottom: 5px;
      font-size: 30px;
      line-height: 48px;
    }
  }

  @media (max-width: 540px) {
    h3 {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.product__text-block {
  &--hidden {
    max-height: 280px;
    overflow-y: hidden;
  }

  p {
    margin: 0;
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 24px;
  }

  @include viewport--sm {
    p {
      margin-bottom: 17px;
      font-size: 24px;
      line-height: 36px;
    }
  }

  @media (max-width: 540px) {
    p {
      font-size: 15px;
      line-height: 24px;
    }
  }
}

.product__text-more {
  @include button-reset;
  display: none;
  color: $color-havelock-blue;
  text-decoration: underline;
  text-decoration-style: dotted;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-brick-red;
    }
  }

  &--show {
    display: block;
  }

  @include viewport--sm {
    font-size: 24px;
  }

  @media (max-width: 540px) {
    font-size: 14px;
  }
}

.product__bottom {
  h2 {
    margin: 0;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 800;
  }

  @include viewport--sm {
    padding-left: 23px;

    h2 {
      margin-bottom: 40px;
      padding-right: 23px;
      font-size: 36px;
      line-height: 40px;
    }
  }

  @media (max-width: 540px) {
    h2 {
      margin-bottom: 25px;
      font-size: 24px;
      line-height: 30px;
    }
  }
}

.product__bottom-buy-books {
  margin-bottom: 65px;

  @include viewport--sm {
    margin-bottom: 111px;
  }

  @media (max-width: 540px) {
    margin-bottom: 50px;
  }
}
