.registration {
  &--tabs {
    .registration__wrapper {
      padding-top: 60px;
      padding-bottom: 187px;
    }

    .registration__important-text {
      max-width: 840px;
      margin-top: 0;
      margin-bottom: 59px;
    }

    .registration__back-main {
      margin-bottom: 10px;
    }

    .registration__button {
      width: 120px;
    }
  }

  &--successful {
    .registration__wrapper {
      padding-top: 100px;
      padding-bottom: 343px;
    }

    .registration__important-text {
      max-width: 550px;
      margin-top: 25px;
      margin-bottom: 19px;

      &--successful-password {
        max-width: 100%;
      }
    }
  }

  &--password {
    .registration__wrapper {
      padding-top: 60px;
      padding-bottom: 292px;
    }

    .registration__important-text {
      margin-bottom: 64px;
    }

    .registration__button {
      width: 180px;
      margin-bottom: 4px;
    }
  }

  @include viewport--lg {
    .registration__wrapper {
      padding-bottom: 200px;
    }
  }

  @include viewport--md {
    .registration__wrapper {
      padding-bottom: 100px;
    }

    &--tabs {
      .registration__button {
        width: 50%;
      }
    }

    &--password {
      .registration__button {
        width: 50%;
      }
    }
  }

  @media (max-width: 440px) {
    .registration__wrapper {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &--tabs {
      .registration__important-text {
        margin-bottom: 30px;
      }
    }
  }
}

.registration__wrapper {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    flex-shrink: 0;
    fill: $color-silver-tree;
  }

  @include viewport--md {
    svg {
      width: 60px;
      height: 60px;
    }
  }

  @media (max-width: 440px) {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}

.registration__important-text {
  margin: 0;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  text-align: center;

  @include viewport--md {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 440px) {
    font-size: 22px;
    line-height: 28px;
  }
}

.registration__important-link {
  color: $color-havelock-blue;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-amaranth;
    }
  }
}

.registration__text {
  max-width: 900px;
  margin: 0;
  margin-bottom: 39px;
  font-size: 15px;
  line-height: 24px;
  text-align: center;

  &--successful-password {
    margin-bottom: 20px;
  }

  @include viewport--md {
    font-size: 21px;
    line-height: 34px;
  }

  @media (max-width: 440px) {
    font-size: 18px;
    line-height: 24px;
  }
}

.registration__button {
  width: 220px;
  margin-bottom: 13px;
  text-align: center;
}

.registration__back-main {
  font-size: 15px;
  line-height: 34px;
  color: $color-lochmara;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-amaranth;
    }
  }

  @include viewport--md {
    font-size: 18px;
  }

  @media (max-width: 440px) {
    font-size: 16px;
  }
}

.registration__controls {
  display: flex;
  max-width: 530px;
  width: 100%;
  margin-bottom: 58px;
}

.registration__control {
  position: relative;
  width: 100%;
  max-width: 265px;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
  color: $color-silver;
  text-align: center;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-white-smoke;
    transition: all $transition;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover::after,
    &:focus::after {
      background-color: $color-amaranth;
    }
  }

  &--current {
    color: $color-black;
    border: 1px solid $color-white-smoke;
    border-bottom: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    &::after {
      content: none;
    }
  }

  @include viewport--md {
    font-size: 24px;
  }

  @media (max-width: 440px) {
    font-size: 18px;
  }
}

.registration__showcase {
  width: 580px;

  @include viewport--md {
    max-width: 530px;
    width: 100%;
  }
}

.registration__showcase-item {
  &--authtorization {
    padding-left: 102px;
  }

  &--registration {
    padding-left: 75px;

    .registration__form-buttons {
      margin-top: 44px;

      .registration__button {
        width: 220px;
      }
    }
  }

  &--password {
    padding-left: 102px;
  }

  &--hidden {
    display: none;
  }

  @include viewport--md {
    &--authtorization {
      padding-left: 0;
    }

    &--registration {
      padding-left: 0;
    }

    &--password {
      padding-left: 0;
    }
  }
}

.registration__form-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 11px;

  @include viewport--md {
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.registration__form-label {
  font-size: 15px;
  line-height: 34px;

  @include viewport--md {
    max-width: 230px;
    font-size: 21px;
  }

  @include viewport--sm {
    max-width: 100%;
  }

  @media (max-width: 440px) {
    font-size: 16px;
  }
}

.registration__form-input {
  width: 282px;

  @include viewport--md {
    font-size: 21px;
  }

  @media (max-width: 991px) {
    width: 100%;
  }

  @media (max-width: 440px) {
    font-size: 16px;
  }
}

.invalid {
  border-color: $color-brick-red;
}

.js-error {
  line-height: 20px;
  color: $color-brick-red;

  @include viewport--md {
    font-size: 16px;
  }

  @media (max-width: 440px) {
    font-size: 12px;
  }
}

.registration__form-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 402px;
  margin-top: 32px;

  @include viewport--md {
    width: 100%;
  }
}

.registration__action {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 378px;
  margin-bottom: 52px;
  padding-top: 13px;
  padding-bottom: 13px;
  border: 1px solid $color-white-smoke;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.registration__action-text {
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
  text-align: center;
}

.registration__note {
  max-width: 441px;
  margin: 0;
  margin-top: 17px;
  margin-bottom: 35px;
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  color: $color-suva-grey;
}
