.not-found-page__wrapper {
  @include container;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 154px;
  padding-bottom: 297px;

  @include viewport--lg {
    padding-top: 100px;
    padding-bottom: 150px;
  }
}

.not-found-page__404 {
  margin: 0;
  font-size: 300px;
  line-height: 1;
  font-weight: 700;
  color: $color-amaranth;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: url("../img/content/circles@1x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include retina {
    background-image: url("../img/content/circles@2x.png");
  }

  @media (max-width: 1199px) {
    font-size: 200px;
  }

  @media (max-width: 991px) {
    font-size: 150px;
  }

  @media (max-width: 440px) {
    font-size: 100px;
  }
}

.not-found-page__text {
  margin: 0;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 800;
  text-align: center;

  @media (max-width: 440px) {
    font-size: 18px;
  }
}

.not-found-page__link {
  font-size: 15px;
  line-height: 34px;
  color: $color-dodger-blue;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-brick-red;
    }
  }
}
