.user-link {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 49px;
  margin-right: 23px;
  line-height: 18px;
  font-weight: 600;
  color: $color-white;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-white-50;

      svg {
        fill: $color-white-50;
      }
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &--mobile-account {
    display: none;
  }

  svg {
    margin-bottom: auto;
    fill: $color-white;
    transition: all $transition;
  }

  @include viewport--md {
    &--account {
      display: none;
    }

    &--mobile-account {
      display: flex;
      flex-direction: row;
      min-height: 100%;
      margin-bottom: 35px;
      font-size: 24px;
      line-height: 48px;

      svg {
        width: 22px;
        height: 32px;
        margin-bottom: 0;
        margin-right: 15px;
      }
    }

    &--cart {
      svg {
        width: 46px;
        height: 43px;
        margin-bottom: 0;
      }

      .user-link__text {
        display: none;
      }
    }
  }

  @media (max-width: 550px) {
    &--mobile-account {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 32px;
    }
  }
}
