.personal-area__wrapper {
  @include viewport--sm {
    padding-left: 23px;
    padding-right: 23px;
  }
}

.personal-area__title {
  margin: 0;
  margin-bottom: 18px;

  @include viewport--sm {
    margin-bottom: 20px;
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 440px) {
    font-size: 24px;
    line-height: 1;
  }
}

.personal-area__notes {
  margin-bottom: 30px;
}

.personal-area__note {
  margin: 0;
  line-height: 20px;
  color: $color-matterhorn;

  @include viewport--sm {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 440px) {
    font-size: 16px;
  }
}

.personal-area__main {
  display: grid;
  grid-template-columns: minmax(350px, 53%) 1fr;
  grid-column-gap: 51px;
  align-items: start;

  @media (max-width: 1140px) {
    grid-column-gap: 30px;
  }

  @include viewport--md {
    grid-template-columns: 1fr;
  }
}

.personal-area__form {
  @media (max-width: 1140px) {
    display: flex;
    flex-direction: column;
  }

  @include viewport--md {
    margin-bottom: 50px;
  }
}

.personal-area__form-field {
  display: grid;
  grid-template-columns: 197px 1fr;
  margin-bottom: 12px;

  @media (max-width: 1140px) {
    grid-template-columns: 165px 1fr;
  }

  @include viewport--md {
    grid-template-columns: 175px 1fr;
  }

  @include viewport--sm {
    grid-template-columns: 1fr;
  }
}

.personal-area__form-lable {
  font-size: 15px;
  line-height: 34px;

  @include viewport--sm {
    font-size: 21px;
  }

  @media (max-width: 440px) {
    font-size: 18px;
  }
}

.personal-area__form-button {
  width: 160px;
  margin-top: 16px;
  margin-left: 197px;

  @media (max-width: 1140px) {
    align-self: center;
    margin-left: 0;
  }

  @include viewport--sm {
    width: 50%;
  }
}

.personal-area__discount {
  padding: 31px 10px 33px 28px;
  border: 2px dashed $color-sweet-corn;
  border-radius: 5px;

  @media (max-width: 440px) {
    padding-left: 10px;
  }
}

.personal-area__discount-total {
  margin: 0;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 36px;

  @include viewport--sm {
    font-size: 32px;
  }

  @media (max-width: 440px) {
    font-size: 24px;
  }
}

.personal-area__discount-value {
  font-weight: 700;
}

.personal-area__discount-note {
  margin: 0;
  font-size: 15px;
  line-height: 24px;

  @include viewport--sm {
    font-size: 21px;
    line-height: 30px;
  }

  @media (max-width: 440px) {
    font-size: 16px;
  }
}

.personal-area__discount-note-value {
  font-weight: 600;
}
