.main-slider {
  margin-bottom: 36px;

  @include viewport--sm {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.main-slider__button-prev,
.main-slider__button-next {
  @include button-reset;

  svg {
    fill: $color-amaranth;
    transition: all $transition;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        fill: $color-old-rose;
      }
    }
  }
}

.main-slider__button-prev {
  svg {
    transform: rotate(180deg);
  }

  @media (max-width: 900px) {
    left: 5px;
  }

  @include viewport--sm {
    left: 10px;
  }
}

.main-slider__button-next {
  @media (max-width: 900px) {
    right: 5px;
  }

  @include viewport--sm {
    right: 10px;
  }
}

.main-slider__button-prev::after,
.main-slider__button-next::after {
  display: none;
}

.main-slider__container > .main-slider__pagination {
  bottom: 35px;

  @media (max-width: 900px) {
    bottom: 10px;
  }

  @include viewport--sm {
    bottom: 35px;
  }
}

.main-slider__container > .main-slider__pagination > .swiper-pagination-bullet {
  margin-left: 7px;
  margin-right: 7px;

  @include viewport--sm {
    margin-left: 17px;
    margin-right: 17px;
  }
}

.main-slider__pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: $color-brick-red;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    position: relative;
    width: 14px;
    height: 14px;
    background-color: transparent;
    border: 1px solid $color-brick-red;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6px;
      height: 6px;
      background-color: $color-brick-red;
      border-radius: 50%;
    }
  }

  @include viewport--sm {
    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
    }

    .swiper-pagination-bullet-active {
      width: 29px;
      height: 29px;

      &::before {
        width: 12px;
        height: 12px;
      }
    }
  }
}

.main-slider__slide {
  position: relative;

  img {
    width: 901px;
    object-fit: cover;
  }

  @include viewport--sm {
    img {
      width: auto;
      height: 388px;
    }
  }
}

.main-slider__info {
  position: absolute;
  top: 21%;
  left: 17%;
  max-width: 355px;

  @media (max-width: 1140px) {
    top: 10%;
    left: 10%;
  }

  @include viewport--md {
    max-width: 255px;
  }

  @media (max-width: 900px) {
    max-width: 200px;
  }

  @include viewport--sm {
    max-width: 355px;
    top: 15%;
    left: 9%;
  }

  @media (max-width: 440px) {
    max-width: 280px;
  }
}

.main-slider__title {
  margin: 0;
  margin-bottom: 11px;
  font-size: 36px;
  line-height: 1;
  color: $color-lucky-point;

  @include viewport--md {
    font-size: 26px;
  }

  @media (max-width: 900px) {
    font-size: 20px;
  }

  @media (max-width: 800px) {
    font-size: 18px;
  }

  @include viewport--sm {
    font-size: 32px;
    line-height: 37px;
  }

  @media (max-width: 440px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.main-slider__price {
  margin: 0;
  margin-bottom: 21px;
  font-size: 60px;
  line-height: 1;
  font-weight: 700;
  color: $color-resolution-blue;

  @include viewport--md {
    font-size: 40px;
  }

  @media (max-width: 900px) {
    margin-bottom: 10px;
    font-size: 30px;
  }

  @media (max-width: 800px) {
    margin-bottom: 7px;
    font-size: 25px;
  }

  @include viewport--sm {
    margin-bottom: 30px;
    font-size: 52px;
  }

  @media (max-width: 440px) {
    margin-bottom: 15px;
    font-size: 34px;
  }
}

.main-slider__params {
  @include list-reset;
  padding-left: 45px;
  line-height: 20px;
  color: $color-lucky-point;

  li {
    position: relative;
    padding-left: 15px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 2px;
      background-color: $color-resolution-blue;
    }
  }

  @include viewport--md {
    padding-left: 25px;
  }

  @media (max-width: 900px) {
    padding-left: 10px;
  }

  @media (max-width: 800px) {
    font-size: 12px;
  }

  @include viewport--sm {
    padding-left: 67px;
    font-size: 17px;
  }

  @media (max-width: 440px) {
    padding-left: 20px;
  }
}
