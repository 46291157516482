.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 160px;

  @include viewport--sm {
    max-width: 301px;
    // flex-shrink: 1;
  }

  @media (max-width: 440px) {
    max-width: 200px;
  }
}

.card__link {
  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      .card__image {
        box-shadow: 0 0 16px 0 rgba($color-dim-gray, 0.34);
      }

      .card__title {
        color: $color-dodger-blue;
      }
    }
  }
}

.card__image {
  margin-bottom: 16px;
  transition: all $transition;

  img {
    width: 157px;
    object-fit: cover;
  }

  @include viewport--sm {
    margin-bottom: 20px;

    img {
      width: 301px;
    }
  }

  @media (max-width: 440px) {
    img {
      width: 200px;
    }
  }
}

.card__author {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: 20px;
  overflow: hidden;
  margin: 0;
  margin-bottom: 4px;
  line-height: 20px;
  font-weight: 700;
  text-overflow: ellipsis;
  color: $color-black;

  @include viewport--sm {
    height: 35px;
    font-size: 21px;
    line-height: 30px;
  }

  @media (max-width: 440px) {
    font-size: 18px;
  }
}

.card__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: 54px;
  overflow: hidden;
  margin: 0;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  color: $color-dim-gray;
  transition: all $transition;

  @include viewport--sm {
    height: 116px;
    font-size: 17px;
    line-height: 30px;
  }

  @media (max-width: 440px) {
    height: 100px;
    line-height: 24px;
  }
}

.card__status {
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 300;

  @include viewport--sm {
    margin-bottom: 18px;
    font-size: 15px;
  }
}

.card__price-list {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  min-height: 45px;
  width: 100%;

  @include viewport--sm {
    min-height: 72px;
  }

  @media (max-width: 440px) {
    min-height: 50px;
  }
}

.card__current-price {
  margin-right: 5px;
  font-size: 17px;
  line-height: 22px;
  font-weight: 800;
  color: $color-black;

  @include viewport--sm {
    margin-right: 20px;
    font-size: 28px;
    line-height: 1;
  }

  @media (max-width: 440px) {
    font-size: 20px;
  }
}

.card__old-price {
  font-size: 13px;
  line-height: 22px;
  text-decoration: line-through;
  color: $color-dim-gray;

  @include viewport--sm {
    font-size: 15px;
    line-height: 1;
  }
}

.card__flags {
  top: 4px;

  @include viewport--sm {
    top: 0;
  }
}
