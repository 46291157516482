.sorting {
  margin-bottom: 23px;
  background-color: $color-white-smoke;

  @include viewport--sm {
    margin-bottom: 40px;
  }
}

.sorting__wrapper {
  padding: 11px 17px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &--search {
    justify-content: normal;
    padding-bottom: 0;

    .sorting__select {
      margin-right: 28px;
      margin-bottom: 11px;
    }

    .sorting__checkbox {
      margin-right: auto;
      margin-bottom: 11px;
      padding-right: 10px;
    }
  }

  @media (max-width: 1110px) {
    &--search {
      justify-content: space-between;

      .sorting__select {
        margin-right: 0;
        padding-right: 10px;
      }

      .sorting__checkbox {
        margin-right: 0;
      }
    }
  }

  @include viewport--md {
    &--search {
      .sorting__select {
        padding-right: 0;
      }

      .sorting__checkbox {
        padding-right: 0;
      }
    }
  }

  @include viewport--sm {
    padding: 25px 42px 11px 23px;

    &--search {
      padding-bottom: 0;

      .sorting__select {
        margin-bottom: 25px;
      }

      .sorting__checkbox {
        margin-bottom: 25px;
      }
    }
  }

  @media (max-width: 440px) {
    padding-right: 23px;
    padding-left: 23px;
  }
}

.sorting__select {
  @include viewport--sm {
    margin-bottom: 15px;
  }
}

.sorting__checkbox {
  @include viewport--sm {
    margin-bottom: 15px;
  }
}

.sorting__cards-types {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 11px;

  @include viewport--sm {
    width: 100%;
    margin-bottom: 25px;
  }
}

.sorting__cards-type {
  margin: 0;
  padding-left: 40px;

  &:first-child {
    padding-left: 0;
  }

  a {
    display: flex;
    align-items: center;
    line-height: 34px;
    color: $color-nero;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-dim-gray;

        svg {
          fill: $color-dim-gray;
          stroke: $color-dim-gray;
        }
      }
    }

    svg {
      margin-right: 6px;
      fill: $color-nero;
      stroke: $color-nero;
      transition: all $transition;
    }
  }

  &--current {
    a {
      color: $color-dim-gray;

      svg {
        fill: $color-dim-gray;
        stroke: $color-dim-gray;
      }
    }
  }

  @include viewport--sm {
    padding-left: 0;

    &:first-child {
      padding-right: 40px;
    }

    a {
      font-size: 21px;

      svg {
        width: 20px;
        height: 19px;
      }
    }
  }

  @media (max-width: 440px) {
    a {
      font-size: 18px;
    }
  }
}
