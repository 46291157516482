.header {
  .header__search-result {
    display: none;
  }

  &--search {
    .header__search-result {
      display: block;
    }
  }
}

.header__top {
  padding-top: 10px;
  background-color: $color-brick-red;

  @include viewport--md {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 20;
    width: 87vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
    padding-top: 37px;
    padding-bottom: 27px;
    transform: translateX(100%);
    transition: all $transition;
    background-color: $color-amaranth;

    &--open {
      transform: translateX(0);
    }

    .header__wrapper {
      flex-direction: column;
      align-items: normal;
      padding-left: 42px;
      padding-right: 39px;
    }
  }

  @media (max-width: 550px) {
    padding-top: 10px;
    padding-bottom: 10px;

    .header__wrapper {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.header__bottom {
  padding-top: 14px;
  padding-bottom: 16px;
  background-color: $color-amaranth;

  @include viewport--sm {
    padding-top: 10px;
    padding-bottom: 13px;
  }
}

.header__wrapper {
  @include container;
  display: flex;
  align-items: center;

  &--top {
    flex-wrap: wrap;
  }

  @media (max-width: 1140px) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @include viewport--md {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include viewport--sm {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media (max-width: 595px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media (max-width: 550px) {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }
}

.header__nav {
  flex-basis: 55%;
  margin-bottom: 10px;

  @media (max-width: 1140px) {
    flex-basis: auto;
    margin-right: auto;
  }

  @include viewport--md {
    position: relative;
    margin-right: 0;
    margin-bottom: 35px;
    padding-bottom: 27px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $color-brick-red;
    }
  }

  @media (max-width: 550px) {
    margin-bottom: 20px;
  }
}

.header__top-info {
  display: flex;
  justify-content: space-between;
  margin-right: auto;
  margin-bottom: 10px;
  padding-right: 15px;

  p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    color: $color-white;

    &:first-child {
      margin-right: 15px;
    }

    &:last-child {
      display: none;
    }

    svg {
      flex-shrink: 0;
      margin-right: 5px;
      fill: $color-flame-red;
    }
  }

  @media (max-width: 1140px) {
    p {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  @include viewport--md {
    position: relative;
    flex-direction: column;
    justify-content: normal;
    margin-right: 0;
    margin-bottom: 31px;
    padding-top: 50px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $color-brick-red;
    }

    p {
      font-size: 24px;

      &:first-child {
        margin-right: 0;
        line-height: 48px;
        font-weight: 300;

        svg {
          width: 18px;
          height: 26px;
          margin-right: 23px;
        }
      }

      &:nth-child(2) {
        margin-bottom: 5px;
        padding-left: 41px;
        line-height: 36px;
        font-weight: 100;

        svg {
          display: none;
        }
      }

      &:last-child {
        display: flex;

        a {
          line-height: 36px;
          font-weight: 400;
          color: $color-white;
          text-decoration: underline;
          transition: all $transition;

          @media (hover: hover), screen and (min-width: 0\0) {
            &:hover,
            &:focus {
              color: $color-white-50;
            }
          }
        }

        svg {
          margin-right: 15px;
        }
      }
    }
  }

  @media (max-width: 550px) {
    padding-top: 25px;

    p {
      font-size: 16px;

      &:first-child {
        line-height: 32px;

        svg {
          margin-right: 14px;
        }
      }

      &:nth-child(2) {
        padding-left: 33px;
        line-height: 32px;
      }

      &:last-child {
        svg {
          margin-right: 7px;
        }
      }
    }
  }
}

.header__top-phone {
  display: flex;
  margin-bottom: 10px;

  p {
    margin: 0;
    line-height: 1;
    font-weight: 600;

    &:last-child {
      margin-left: 10px;
    }
  }

  svg {
    display: none;
  }

  @media (max-width: 1140px) {
    p {
      &:last-child {
        margin-left: 5px;
      }
    }
  }

  @include viewport--md {
    align-items: center;

    svg {
      display: block;
      margin-right: 18px;
      fill: $color-flame-red;
    }

    p {
      font-size: 24px;
      line-height: 26px;

      &:last-child {
        margin-left: 18px;
      }
    }

    .header__top-phone-operator {
      font-weight: 300;
      color: $color-white;
    }

    .header__top-phone-number {
      font-size: 30px;
    }
  }

  @media (max-width: 550px) {
    svg {
      margin-right: 10px;
    }

    p {
      font-size: 16px;

      &:last-child {
        margin-left: 10px;
      }
    }

    .header__top-phone-number {
      font-size: 20px;
    }
  }
}

.header__top-phone-operator {
  color: $color-flame-red;
}

.header__top-phone-number {
  color: $color-white;
}

.header__logo {
  margin-right: 38px;
  padding-left: 38px;
  flex-shrink: 0;

  @media (max-width: 1140px) {
    padding-left: 19px;
  }

  @include viewport--md {
    padding-left: 1px;
    margin-right: 20px;
  }

  @include viewport--sm {
    margin-right: 20px;
  }

  @media (max-width: 550px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
}

.header__search {
  position: relative;
  max-width: 923px;
  width: 100%;
  margin-right: 40px;

  @include viewport--md {
    margin-right: 20px;
  }

  @include viewport--sm {
    max-width: 420px;
    margin-right: 40px;
  }

  @media (max-width: 595px) {
    padding-right: 10px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    margin-right: 0;
    padding-right: 0;
  }
}

.header__search-form {
  display: flex;
  align-items: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: $color-dark-gray;

  input {
    flex-grow: 1;
    width: 100%;
    padding: 16px 26px 15px;
    font: inherit;
    color: inherit;
    background-color: $color-white;
    border: none;
    transition: all $transition;
    outline: none;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        box-shadow: 0 0 18px 0 rgba(15, 15, 15, 0.4);
      }
    }

    &::placeholder {
      color: inherit;
      font-weight: 100;
    }
  }

  button {
    @include button-reset;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 49px;
    background-color: $color-brick-red-dark;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-radical-red;
      }
    }

    svg {
      fill: $color-white;
    }
  }
}

.header__search-result {
  position: absolute;
  top: 48px;
  z-index: 5;
}

.header__user {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include viewport--md {
    margin-right: 20px;
  }

  @media (max-width: 550px) {
    position: absolute;
    top: 20px;
    right: 100px;
    margin-right: 0;
  }
}

.header__cart-quantity {
  position: absolute;
  top: -10px;
  right: 0;
  width: 18px;
  height: 18px;
  padding-top: 2px;
  font-size: 12px;
  text-align: center;
  color: $color-white;
  border-radius: 50%;
  background-color: $color-summer-green;

  @include viewport--md {
    top: -5px;
    right: -10px;
    width: 26px;
    height: 26px;
    padding-top: 5px;
    font-size: 14px;
  }
}

.header__mobile-btn {
  @include button-reset;
  display: none;
  width: 48px;
  height: 36px;
  margin-left: 11px;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        fill: $color-white-50;
      }
    }
  }

  svg {
    fill: $color-white;
    transition: all $transition;
  }

  @include viewport--md {
    display: block;
  }

  @media (max-width: 550px) {
    position: absolute;
    top: 25px;
    right: 10px;
    margin-left: 0;
  }
}

.header__mobile-btn-close {
  @include button-reset;
  position: absolute;
  top: 35px;
  right: 36px;
  display: none;
  width: 29px;
  height: 28px;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        fill: $color-white-50;
      }
    }
  }

  svg {
    fill: $color-white;
    transition: all $transition;
  }

  @include viewport--md {
    display: block;
  }

  @media (max-width: 550px) {
    top: 10px;
    right: 10px;
    width: 20px;
    height: 19px;

    svg {
      width: 20px;
      height: 19px;
    }
  }
}
