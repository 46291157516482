.button-submit {
  @include button-reset;
  padding: 4px 5px 5px;
  line-height: 24px;
  color: $color-white;
  background-color: $color-lochmara;
  border-radius: 5px;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-havelock-blue;
    }
  }

  &:active {
    background-color: $color-cobalt;
  }

  @include viewport--sm {
    padding-top: 9px;
    padding-bottom: 10px;
    font-size: 18px;
  }

  @media (max-width: 440px) {
    font-size: 16px;
  }
}
