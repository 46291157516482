.banner {
  background-color: $color-royal-blue;
  background-image: url("../img/content/banner-background@1x.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top -50px right 0;

  @include retina {
    background-image: url("../img/content/banner-background@2x.png");
  }

  @include viewport--md {
    background-position: top -35px right 0;
  }

  @include viewport--sm {
    background-position: top -15px right 0;
  }
}

.banner__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 76px;

  p {
    margin: 0;
    font-size: 30px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    color: $color-white;
    text-align: center;
  }
}
