.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;

  span {
    margin-right: 30px;
    font-size: 16px;
    line-height: 1;
  }

  @include viewport--sm {
    span {
      font-size: 30px;
    }
  }

  @media (max-width: 440px) {
    span {
      font-size: 22px;
    }
  }
}

.pagination__list {
  @include list-reset;
  display: flex;
  margin-right: 25px;
}

.pagination__list-item {
  font-size: 16px;
  line-height: 24px;
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }

  .pagination__list-link {
    color: $color-black;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-dodger-blue;
      }
    }
  }

  &--current {
    .pagination__list-link {
      color: $color-brick-red;
    }
  }

  @include viewport--sm {
    font-size: 30px;
    line-height: 1;
  }

  @media (max-width: 440px) {
    font-size: 22px;
  }
}

.pagination__next-page {
  svg {
    stroke: $color-black;
    transition: all $transition;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        stroke: $color-dodger-blue;
      }
    }
  }

  @include viewport--sm {
    svg {
      width: 25px;
      height: 18px;
    }
  }

  @media (max-width: 440px) {
    svg {
      width: 21px;
      height: 14px;
    }
  }
}
