.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;

  &--no-scrollbar { // use it for min-height: 100vh pages to visually disable scrollbar
    height: 100vh;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }
}

.scroll-lock {
  position: fixed;
  overflow-y: scroll;

  &::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    background-color: $color-black-50;
  }
}
