.catalog__title {
  margin: 0;
  margin-bottom: 25px;

  @include viewport--sm {
    margin-bottom: 38px;
    padding: 0 23px;
    font-size: 36px;
  }

  @media (max-width: 440px) {
    font-size: 24px;
  }
}

.catalog__title-quantity {
  font-size: 12px;
  font-weight: 300;
  color: $color-silver;

  @include viewport--sm {
    font-size: 24px;
  }

  @media (max-width: 440px) {
    font-size: 18px;
  }
}
