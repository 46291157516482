.button-side-menu {
  @include button-reset;
  width: 100%;
  padding: 27px;
  font-size: 26px;
  line-height: 24px;
  border: 1px solid $color-gainsboro;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-havelock-blue;
    }
  }

  &--sections {
    font-weight: 800;
  }

  @media (max-width: 440px) {
    padding: 20px;
    font-size: 24px;
  }
}
