.price-list {
  &--catalog {
    .price-list__box {
      @include viewport--sm {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 19px;
        grid-row-gap: 40px;
      }

      @media (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
      }
    }

    @include viewport--sm {
      padding-left: 23px;
      padding-right: 23px;
    }
  }
}

.price-list__box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 26px;
  grid-row-gap: 63px;

  @media (max-width: 1240px) {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 19px;
    grid-row-gap: 40px;
  }

  @include viewport--md {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include viewport--sm {
    display: flex;
  }
}
