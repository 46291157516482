.filter {
  position: relative;
  width: 274px;
  margin: 0 auto;
  background-color: $color-white-smoke;

  &::after {
    content: "";
    position: absolute;
    bottom: 125px;
    left: 50%;
    transform: translateX(-50%);
    width: 82%;
    height: 1px;
    background-color: $color-quartz;
  }

  &--hidden {
    display: none;
  }

  @include viewport--sm {
    width: 100%;
    background-color: $color-white;

    &::after {
      bottom: 17%;
      left: 49%;
      width: 87%;
    }
  }

  @media (max-width: 488px) {
    &::after {
      bottom: 21%;
    }
  }

  @media (max-width: 440px) {
    &::after {
      bottom: 23%;
    }
  }
}

.filter__wrapper {
  padding: 31px 25px 35px;

  @include viewport--sm {
    padding: 18px 45px 20px 31px;
  }

  @media (max-width: 440px) {
    padding-top: 0;
    padding-left: 9px;
    padding-right: 21px;
    padding-bottom: 0;
  }
}

.filter__title {
  margin: 0;
  margin-bottom: 8px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;
  text-align: center;

  @include viewport--sm {
    margin-bottom: 40px;
    font-size: 28px;
    text-align: left;
  }

  @media (max-width: 440px) {
    font-size: 24px;
  }
}

.filter__form {
  display: flex;
  flex-direction: column;
}

.filter__form-field {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 2px;

  @include viewport--sm {
    margin-bottom: 35px;
  }

  @media (max-width: 440px) {
    margin-bottom: 25px;
  }
}

.filter__form-label {
  line-height: 33px;

  @include viewport--sm {
    margin-bottom: 7px;
    font-size: 21px;
    line-height: 34px;
  }

  @media (max-width: 440px) {
    font-size: 18px;
    line-height: 30px;
  }
}

.filter__form-input {
  padding: 0 13px;
  font-size: 14px;
  line-height: 17px;

  &::placeholder {
    font-weight: 300;
    color: $color-nobel;
    opacity: 1;
  }

  @include viewport--sm {
    padding: 12px 28px 13px;
    font-size: 21px;
  }

  @media (max-width: 440px) {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
  }
}

.filter__form-svg {
  position: absolute;
  right: 11px;
  top: 43px;
  fill: $color-nobel;

  @include viewport--md {
    top: 47px;
  }

  @include viewport--sm {
    right: 22px;
    top: 52px;
    width: 24px;
    height: 24px;
  }

  @media (max-width: 440px) {
    top: 49px;
    width: 20px;
    height: 20px;
  }
}

.filter__form-select {
  button {
    width: 100%;
    line-height: 30px;
    font-weight: 300;
    color: $color-nobel;

    svg {
      color: $color-nobel;
    }
  }

  ul {
    top: 33px;
  }

  @include viewport--sm {
    button {
      padding-top: 12px;
      padding-bottom: 13px;
      padding-left: 28px;
      padding-right: 20px;
    }

    ul {
      top: 61px;
    }
  }

  @media (max-width: 440px) {
    button {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.filter__form-price {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  @include viewport--sm {
    margin-bottom: 55px;
  }
}

.filter__form-price-item {
  display: flex;
  flex-direction: column;
  width: 46.5%;

  label {
    margin-bottom: 2px;
    line-height: 1;
    color: $color-nobel;
  }

  @include viewport--sm {
    width: 48.5%;

    label {
      display: none;
    }
  }
}

.filter__form-range-box {
  .noUi-horizontal {
    height: 2px;
  }

  .noUi-target {
    background-color: $color-gainsboro;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }

  .noUi-connects {
    border-radius: 0;
  }

  .noUi-connect {
    background-color: $color-brick-red;
  }

  .noUi-handle {
    top: 6px;
    right: -6px;
    width: 12px;
    height: 10px;
    background-color: $color-light-grey;
    border: none;
    border-radius: 0;
    box-shadow: 0.53px 0.848px 2px 0 rgba(146, 145, 145, 0.75);
    cursor: pointer;
    outline: none;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-havelock-blue;
      }

      &:hover::after,
      &:focus::after {
        border-bottom-color: $color-havelock-blue;
      }
    }

    &::before {
      content: none;
    }

    &::after {
      left: 0;
      top: -6px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid $color-light-grey;
      background-color: transparent;
      transition: all $transition;
    }
  }

  @include viewport--sm {
    .noUi-horizontal {
      height: 4px;
    }

    .noUi-handle {
      top: 17px;
      right: -12px;
      width: 24px;
      height: 21px;

      &::after {
        top: -12px;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 12px solid $color-light-grey;
      }
    }
  }

  @media (max-width: 440px) {
    .noUi-handle {
      top: 14px;
      right: -9px;
      width: 18px;
      height: 15px;

      &::after {
        top: -9px;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-bottom: 9px solid $color-light-grey;
      }
    }
  }
}

.filter__form-buttons-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;

  @include viewport--sm {
    flex-direction: row;
    align-self: center;
    max-width: 470px;
    width: 100%;
    margin-top: 115px;
    justify-content: space-between;
  }

  @media (max-width: 488px) {
    justify-content: center;
    margin-top: 90px;
  }

  @media (max-width: 440px) {
    flex-direction: column;
  }
}

.filter__button-submit {
  width: 120px;
  margin-bottom: 1px;
  padding: 3px 10px 4px !important;

  @include viewport--sm {
    width: 216px;
    margin-bottom: 15px;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    font-size: 22px !important;
  }

  @media (max-width: 440px) {
    width: 100%;
    margin-bottom: 25px;
    font-size: 18px !important;
  }
}

.filter__button-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 34px;
  color: $color-dodger-blue;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-amaranth;

      svg {
        fill: $color-amaranth;
      }
    }
  }

  svg {
    flex-shrink: 0;
    margin-right: 5px;
    fill: $color-dodger-blue;
    transition: all $transition;
  }

  @include viewport--sm {
    margin-bottom: 15px;
    padding-left: 10px;
    font-size: 21px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  @media (max-width: 440px) {
    padding-left: 0;
    font-size: 18px;
  }
}
