.nav__list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  font-size: 13px;
  line-height: 14px;
  font-weight: 600;

  @include viewport--md {
    flex-direction: column;
    font-size: 30px;
    line-height: 60px;
    font-weight: 400;
  }

  @media (max-width: 550px) {
    font-size: 20px;
    line-height: 40px;
  }
}

.nav__item {
  margin-right: 18px;

  &:last-child {
    margin-right: 0;
  }

  a {
    color: $color-white;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-white-50;
      }
    }
  }

  @media (max-width: 1140px) {
    margin-right: 10px;
  }

  @include viewport--md {
    margin-right: 0;
  }
}
