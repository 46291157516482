.contacts__wrapper {
  @include viewport--sm {
    padding: 0 23px;
  }
}

.contacts__title {
  margin: 0;
  margin-bottom: 40px;

  @include viewport--md {
    margin-bottom: 20px;
  }

  @include viewport--sm {
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 440px) {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 1;
  }
}

.contacts__group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;

  @include viewport--md {
    margin-bottom: 30px;
  }

  @media (max-width: 440px) {
    flex-direction: column;
  }
}

.contacts__item {
  width: 207px;
  margin-right: 19px;
  margin-bottom: 18px;
  padding-right: 5px;

  &:last-child {
    margin-right: 0;
  }

  @include viewport--sm {
    width: 310px;
  }

  @media (max-width: 440px) {
    width: 265px;
    margin-right: 0;
    padding-right: 0;
  }
}

.contacts__item-info {
  margin: 0;
  line-height: 24px;

  span {
    font-weight: 600;
  }

  a {
    color: $color-royal-blue;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-brick-red;
      }
    }
  }

  @include viewport--sm {
    font-size: 21px;
    line-height: 34px;
  }

  @media (max-width: 440px) {
    font-size: 16px;
    line-height: 30px;
  }
}

.contacts__map {
  max-width: 901px;
  width: 100%;
  height: 525px;
  background-image: url("../img/content/map-bg@1x.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include retina {
    background-image: url("../img/content/map-bg@2x.jpg");
  }

  @include viewport--md {
    height: 325px;
  }

  @include viewport--sm {
    height: 425px;
  }

  @media (max-width: 440px) {
    height: 225px;
  }
}
