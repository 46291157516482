.button-order {
  @include button-reset;
  width: 121px;
  padding: 2px 5px;
  line-height: 1;
  color: $color-white;
  border-radius: 5px;
  transition: all $transition;

  &--bestseller {
    padding-top: 8px;
    padding-bottom: 11px;
    border: 1px solid $color-white;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-white-50;
        border-color: $color-white-50;
      }
    }

    &:active {
      color: $color-silver-tree;
      background-color: $color-white;
    }
  }

  &--order {
    padding-top: 8px;
    padding-bottom: 11px;
    background-color: $color-brick-red;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-radical-red;
      }
    }

    &:active {
      background-color: $color-brick-red-dark;
    }
  }

  &--report {
    padding-bottom: 3px;
    color: $color-shadow;
    background-color: $color-oasis;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        background-color: $color-drover;
      }
    }

    &:active {
      background-color: $color-sweet-corn;
    }
  }

  @include viewport--sm {
    width: 214px;
    padding-top: 16px;
    padding-bottom: 18px;
    font-size: 24px;

    &--report {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
