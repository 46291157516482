.product-items {
  position: relative;
  margin-bottom: 46px;
  padding-bottom: 64px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-white-smoke;
  }

  &--sale {
    margin-bottom: 0;

    &::after {
      display: none;
    }
  }

  @include viewport--sm {
    margin-bottom: 45px;
    padding-bottom: 77px;
    padding-left: 23px;

    &--sale {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  @media (max-width: 440px) {
    margin-bottom: 50px;
    padding-bottom: 50px;

    &--sale {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}

.product-items__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @include viewport--sm {
    flex-wrap: wrap;
    margin-bottom: 30px;
    padding-right: 23px;
  }
}

.product-items__title {
  margin: 0;
  font-size: 24px;
  line-height: 1;
  font-weight: 800;

  @include viewport--sm {
    margin-bottom: 10px;
    padding-right: 15px;
    font-size: 30px;
  }

  @media (max-width: 440px) {
    font-size: 24px;
  }
}

.product-items__more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130px;
  padding-left: 9px;
  padding-right: 7px;
  line-height: 30px;
  color: $color-dark-gray;
  border: 1px solid $color-dark-gray;
  border-radius: 10px;
  transition: all $transition;

  svg {
    flex-shrink: 0;
    fill: $color-dark-gray;
    transition: all $transition;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-dodger-blue;

      svg {
        fill: $color-dodger-blue;
      }
    }
  }

  @include viewport--sm {
    width: 178px;
    padding: 10px 15px 8px 17px;
    font-size: 18px;
  }

  @media (max-width: 440px) {
    position: relative;
    justify-content: normal;
    width: 160px;
    font-size: 16px;

    svg {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
    }
  }
}
