.custom-checkbox__checkbox:checked + .custom-checkbox__label::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 9px;
  background-image: url("../img/sprite/check-checkbox-icon.svg");
  background-repeat: no-repeat;
  background-size: 18px 9px;

  @include viewport--sm {
    left: 4px;
    width: 25px;
    height: 16px;
    background-size: 25px 16px;
  }

  @media (max-width: 440px) {
    width: 21px;
    height: 12px;
    background-size: 21px 12px;
  }
}

.custom-checkbox__label {
  position: relative;
  padding-left: 30px;
  line-height: 34px;
  color: $color-nero;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 21px;
    height: 21px;
    background-color: $color-white;
    border: 1px solid $color-gainsboro;
    border-radius: 5px;
    transition: all $transition;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover::before,
    &:focus::before {
      border-color: $color-havelock-blue;
    }
  }

  @include viewport--sm {
    padding-left: 41px;
    font-size: 21px;

    &::before {
      width: 32px;
      height: 32px;
    }
  }

  @media (max-width: 440px) {
    padding-left: 35px;
    font-size: 18px;

    &::before {
      width: 27px;
      height: 27px;
    }
  }
}
