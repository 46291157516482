.footer {
  padding-top: 85px;
  padding-bottom: 130px;
  background-color: $color-brick-red-dark;

  @media (max-width: 1140px) {
    padding-bottom: 90px;
  }

  @include viewport--md {
    padding-bottom: 73px;
  }

  @include viewport--sm {
    padding-top: 0;
    padding-bottom: 50px;
  }
}

.footer__button-up {
  @include button-reset;
  display: none;
  width: 100%;
  padding: 39px 10px 45px;
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  color: $color-white;
  background-color: $color-bright-red;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      background-color: $color-radical-red;
    }
  }

  @include viewport--sm {
    display: block;
  }

  @media (max-width: 550px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.footer__wrapper {
  @include container;
  display: flex;
  align-items: flex-start;

  @include viewport--sm {
    flex-direction: column;
    align-items: center;
    padding-top: 94px;
  }

  @media (max-width: 550px) {
    padding-top: 50px;
  }
}

.footer__left {
  flex-shrink: 0;
  margin-left: 40px;
  margin-right: 44px;

  @media (max-width: 1140px) {
    margin-left: 0;
    margin-right: 49px;
  }

  @include viewport--sm {
    display: none;
  }
}

.footer__logo {
  img {
    width: 104px;
    object-fit: cover;
  }
}

.footer__middle {
  margin-right: auto;
  padding-right: 10px;

  @include viewport--sm {
    display: flex;
    flex-direction: column;
    align-items: center;
    order: 1;
    margin-right: 0;
  }
}

.footer__middle-top {
  display: flex;
  align-items: flex-start;
  margin-bottom: 94px;

  @media (max-width: 1140px) {
    margin-bottom: 60px;
  }

  @include viewport--md {
    flex-direction: column-reverse;
    margin-bottom: 40px;
  }
}

.footer__nav {
  @include viewport--md {
    margin-bottom: 30px;
  }

  @include viewport--sm {
    display: none;
  }
}

.footer__nav-list {
  @include list-reset;
  column-count: 2;
  column-gap: 0;

  @media (max-width: 1140px) {
    column-count: 1;
  }

  @include viewport--md {
    column-count: 2;
  }
}

.footer__nav-item {
  a {
    font-size: 15px;
    line-height: 30px;
    font-weight: 600;
    color: $color-white;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-white-50;
      }
    }
  }
}

.footer__info {
  width: 100%;
  max-width: 360px;
  margin-right: 37px;

  p {
    max-width: 530px;
    margin: 0;
    line-height: 20px;
    font-weight: 100;
    color: $color-white;

    &:first-child {
      margin-bottom: 14px;
    }

    span {
      font-weight: 400;
    }
  }

  @include viewport--md {
    margin-right: 0;
  }

  @include viewport--sm {
    order: 1;
    max-width: 100%;
    margin-bottom: 0;

    p {
      max-width: 100%;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
  }

  @media (max-width: 550px) {
    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.footer__payment-title {
  margin: 0;
  margin-bottom: 10px;
  line-height: 20px;
  color: $color-white;

  @include viewport--sm {
    margin-bottom: 29px;
    font-size: 30px;
    line-height: 1;
    font-weight: 700;
    text-align: center;
  }

  @media (max-width: 550px) {
    font-size: 24px;
  }
}

.footer__payment-list {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include viewport--sm {
    justify-content: center;
    padding-left: 30px;
  }
}

.footer__payment-item {
  margin-right: 27px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  @include viewport--sm {
    margin-bottom: 52px;
    margin-right: 40px;

    &--visa {
      svg {
        width: 95px;
        height: 32px;
      }
    }

    &--visa-verified {
      svg {
        width: 94px;
        height: 43px;
      }
    }

    &--mc {
      svg {
        width: 74px;
        height: 47px;
      }
    }

    &--mc-sc {
      svg {
        width: 118px;
        height: 38px;
      }
    }

    &--belcart {
      svg {
        width: 55px;
        height: 60px;
      }
    }

    &--belcart-password {
      svg {
        width: 126px;
        height: 30px;
      }
    }

    &--bepaid {
      svg {
        width: 113px;
        height: 27px;
      }
    }
  }

  @media (max-width: 550px) {
    flex-grow: 1;
    margin-right: 25px;
  }
}

.footer__right {
  @include viewport--sm {
    width: 100%;
    margin-bottom: 160px;
  }

  @media (max-width: 550px) {
    margin-bottom: 70px;
  }
}

.footer__contact {
  color: $color-white;
  font-size: 15px;
  line-height: 24px;

  p {
    display: flex;
    align-items: center;
    margin: 0;
  }

  svg {
    flex-shrink: 0;
    fill: $color-flame-red;
  }

  .footer__contact-title {
    margin-bottom: 10px;
    padding-left: 26px;
    font-weight: 700;
  }

  .footer__contact-address {
    align-items: flex-start;
    width: 255px;
    margin-bottom: 14px;
    font-weight: 300;

    svg {
      margin-top: 5px;
      margin-right: 10px;
    }
  }

  .footer__contact-mail {
    margin-bottom: 17px;

    svg {
      margin-right: 10px;
    }

    a {
      line-height: 30px;
      text-decoration: underline;
      color: $color-white;
      transition: all $transition;

      @media (hover: hover), screen and (min-width: 0\0) {
        &:hover,
        &:focus {
          color: $color-white-50;
        }
      }
    }
  }

  .footer__contacts-phone-title {
    display: none;
  }

  .footer__contact-phone {
    display: flex;
    align-items: center;
    line-height: 26px;

    svg {
      margin-right: 10px;
    }
  }

  .footer__contact-operator {
    margin-right: 5px;
    font-weight: 300;
  }

  .footer__contact-number {
    font-size: 18px;
    font-weight: 600;
  }

  @include viewport--sm {
    font-size: 30px;

    p {
      justify-content: center;
    }

    .footer__contact-title {
      margin-bottom: 19px;
      padding-left: 0;
      line-height: 30px;
    }

    .footer__contact-address {
      width: 100%;
      margin-bottom: 20px;
      line-height: 48px;

      svg {
        width: 24px;
        height: 34px;
        margin-top: 6px;
        margin-right: 0;
      }

      span {
        margin-left: 22px;
        text-align: center;
      }
    }

    .footer__contact-mail {
      margin-bottom: 83px;
      line-height: 48px;

      svg {
        margin-right: 0;
        margin-bottom: 4px;
        width: 32px;
        height: 24px;
      }

      a {
        margin-left: 22px;
      }
    }

    .footer__contacts-phone-title {
      display: flex;
      margin-bottom: 30px;
      line-height: 30px;
      font-weight: 700;
    }

    .footer__contact-phone {
      justify-content: center;

      svg {
        width: 32px;
        height: 32px;
        margin-right: 0;
      }

      p {
        line-height: 26px;
      }
    }

    .footer__contact-operator {
      margin-left: 22px;
      margin-right: 10px;
      font-size: 24px;
    }

    .footer__contact-number {
      font-size: 36px;
    }
  }

  @media (max-width: 550px) {
    font-size: 24px;

    svg {
      display: none;
    }

    .footer__contact-title,
    .footer__contact-mail {
      line-height: 1;
    }

    .footer__contact-address {
      line-height: 36px;

      span {
        margin-left: 0;
      }
    }

    .footer__contact-mail {
      margin-bottom: 50px;

      a {
        margin-left: 0;
      }
    }

    .footer__contacts-phone-title {
      margin-bottom: 19px;
    }

    .footer__contact-phone {
      flex-wrap: wrap;
    }

    .footer__contact-operator {
      margin-left: 0;
      margin-bottom: 10px;
    }

    .footer__contact-number {
      font-size: 30px;
    }
  }
}
