.organization-info {
  p {
    margin: 0;
    font-size: 15px;
    line-height: 24px;
  }

  @include viewport--sm {
    p {
      padding: 0 23px;
      font-size: 21px;
      line-height: 34px;
    }
  }

  @media (max-width: 440px) {
    p {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.organization-info__title {
  margin: 0;
  margin-bottom: 19px;

  @include viewport--sm {
    padding: 0 23px;
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 440px) {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 1;
  }
}

.organization-info__first-block {
  margin-bottom: 24px;

  p {
    margin: 0;
    margin-bottom: 12px;
  }

  @include viewport--sm {
    padding: 0 23px;
    margin-bottom: 30px;

    p {
      margin-bottom: 15px;
      padding: 0;
      font-size: 21px;
      line-height: 34px;
    }
  }

  @media (max-width: 440px) {
    p {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.organization-info__cashless ul,
.organization-info__cash ul {
  padding-left: 26px;

  @media (max-width: 440px) {
    padding-left: 18px;
    font-size: 18px;
    line-height: 30px;
  }
}

.organization-info__cashless ul li,
.organization-info__cash ul li {
  margin-bottom: 6px;

  @media (max-width: 440px) {
    padding-left: 0;
  }
}

.organization-info__cashless ul li:last-child,
.organization-info__cash ul li:last-child {
  margin-bottom: 0;
}

.organization-info__cashless {
  margin-bottom: 31px;

  p {
    margin: 0;
    margin-bottom: 5px;
  }

  @include viewport--sm {
    margin-bottom: 40px;
    padding: 0 23px;

    p {
      margin-bottom: 15px;
      padding: 0;
      font-size: 21px;
      line-height: 34px;
    }
  }

  @media (max-width: 440px) {
    margin-bottom: 25px;

    p {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.organization-info__cash {
  margin-bottom: 31px;

  p {
    margin: 0;
    margin-bottom: 6px;
  }

  @include viewport--sm {
    margin-bottom: 40px;
    padding: 0 23px;

    p {
      margin-bottom: 15px;
      padding: 0;
      font-size: 21px;
      line-height: 34px;
    }
  }

  @media (max-width: 440px) {
    margin-bottom: 25px;

    p {
      font-size: 18px;
      line-height: 30px;
    }
  }
}

.organization-info__partners {
  margin-top: 43px;

  h3 {
    margin: 0;
    margin-bottom: 23px;
    font-size: 20px;
    line-height: 36px;
    font-weight: 800;
  }

  @include viewport--sm {
    margin-top: 50px;

    h3 {
      margin-bottom: 25px;
      padding: 0 23px;
      font-size: 25px;
    }
  }

  @media (max-width: 440px) {
    margin-top: 30px;

    h3 {
      margin-bottom: 15px;
      font-size: 22px;
    }
  }
}

.organization-info__partners-list {
  @include list-reset;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  background-color: $color-whisper;

  @include viewport--md {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 440px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.organization-info__partners-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  background-color: $color-white;
}

.organization-info__partners-img {
  display: flex;
  align-items: center;
  min-height: 117px;
}

.organization-info__partners-title {
  display: flex;
  align-items: flex-start;
  min-height: 47px;
  padding: 0 10px;
  font-size: 13px;
  line-height: 24px;
  color: $color-suva-grey;
  text-align: center;

  @include viewport--sm {
    font-size: 16px;
  }

  @media (max-width: 440px) {
    font-size: 14px;
  }
}
