.search-result__title {
  margin: 0;
  margin-bottom: 27px;
  font-size: 24px;
  line-height: 1;
  font-weight: 800;

  @include viewport--sm {
    padding-left: 23px;
    padding-right: 23px;
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 440px) {
    font-size: 24px;
  }
}
