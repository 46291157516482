.custom-select {
  position: relative;
}

.custom-select__bar {
  @include button-reset;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 242px;
  padding-left: 13px;
  padding-right: 7px;
  background-color: $color-white;
  border: 1px solid $color-gainsboro;
  border-radius: 5px;
  line-height: 34px;
  color: $color-nero;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-havelock-blue;
    }
  }

  svg {
    fill: $color-silver;
  }

  @include viewport--sm {
    width: 335px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 13px;
    font-size: 21px;

    svg {
      width: 12px;
      height: 17px;
    }
  }

  @media (max-width: 440px) {
    width: 280px;
    font-size: 18px;
  }
}

.custom-select__options {
  @include list-reset;
  position: absolute;
  top: 37px;
  z-index: 2;
  width: 100%;
  display: none;
  padding: 14px 13px;
  background-color: $color-white;
  border: 1px solid $color-gainsboro;
  border-radius: 5px;
  line-height: 20px;
  cursor: pointer;

  &--hidden {
    display: none;
  }

  li {
    margin-bottom: 10px;
    transition: all $transition;

    &:last-child {
      margin-bottom: 0;
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-dodger-blue;
      }
    }
  }

  @include viewport--sm {
    top: 58px;
    font-size: 21px;
    line-height: 34px;
  }

  @media (max-width: 440px) {
    font-size: 18px;
    line-height: 30px;
  }
}

.custom-select.opened {
  .custom-select__options {
    display: block;
  }
}
