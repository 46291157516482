.flags-product {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.flags-product__item {
  width: 37px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 21px;
  color: $color-white;
  text-align: center;

  &--bestseller {
    width: 65px;
    background-color: $color-neon-pink;
  }

  &--new {
    color: $color-spectra;
    background-color: $color-vista-blue;
  }

  &--discount {
    background-color: $color-pumpkin;
  }

  @include viewport--sm {
    width: 60px;
    font-size: 15px;
    line-height: 28px;

    &--bestseller {
      width: 100px;
    }
  }
}
