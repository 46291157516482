.input {
  padding: 6px 10px 7px;
  border: 1px solid $color-gainsboro;
  border-radius: 5px;
  outline: none;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      border-color: $color-havelock-blue;
    }
  }

  @include viewport--md {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
