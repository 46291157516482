.modal {
  position: fixed;
  z-index: 30;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  max-width: 582px;
  width: 100%;
  background-color: $color-white;

  &--show {
    display: block;
  }

  @include viewport--sm {
    width: calc(100% - 20px);
  }
}

.modal__wrapper {
  padding: 90px 70px;

  @include viewport--sm {
    padding: 80px 30px;
  }
}

.modal__close {
  @include button-reset;
  position: absolute;
  top: 18px;
  right: 17px;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        fill: $color-silver;
      }
    }
  }

  svg {
    transition: all $transition;
  }
}

.modal__header {
  margin: 0;
  margin-bottom: 15px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  text-align: center;
}

.modal__text {
  margin: 0;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
}

.modal__form {
  margin-top: 30px;
}

.modal__form-field {
  display: grid;
  grid-template-columns: 150px 1fr;
  margin-bottom: 15px;

  textarea {
    height: 90px;
    resize: none;
  }

  @media (max-width: 550px) {
    grid-template-columns: 1fr;
  }
}

.modal__form-label {
  font-size: 15px;
  line-height: 34px;
}

.modal__form-button {
  width: 160px;
  margin-left: 150px;
  margin-top: 10px;

  @media (max-width: 550px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 35px;
  }
}
