.side-menu {
  @include viewport--sm {
    display: none;
    width: 100%;
    padding-top: 3px;
    padding-bottom: 31px;
    padding-left: 12px;
    background-color: $color-white;

    &--show {
      display: block;
    }
  }
}

.side-menu__button-close {
  @include button-reset;
  position: absolute;
  top: 45px;
  right: 38px;
  z-index: 15;
  display: none;

  svg {
    transition: all $transition;
  }

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      svg {
        fill: $color-dim-gray;
      }
    }
  }

  @include viewport--sm {
    display: block;
  }

  @media (max-width: 540px) {
    right: 20px;
    top: 15px;

    svg {
      width: 25px;
      height: 25px;
    }
  }
}

.side-menu__category {
  margin-bottom: 53px;
  padding-bottom: 43px;
  border: 1px solid $color-whisper;

  &--hidden {
    display: none;
  }

  @include viewport--sm {
    margin-bottom: 0;
    border: none;
  }

  @media (max-width: 540px) {
    padding-bottom: 0;
  }
}

.side-menu__title {
  position: relative;
  margin: 0;
  padding: 19px 29px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 800;

  &::after {
    content: "";
    position: absolute;
    right: 83px;
    top: 0;
    width: 1px;
    height: 100%;
    background-color: $color-whisper;
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-whisper;
  }

  @media (max-width: 1140px) {
    &::after {
      right: 65px;
    }
  }

  @media (max-width: 900px) {
    &::after {
      right: 35px;
    }
  }

  @include viewport--sm {
    margin-bottom: 2px;
    font-size: 31px;
    line-height: 1;

    &::after {
      display: none;
    }

    &::before {
      display: none;
    }
  }

  @media (max-width: 540px) {
    padding-right: 20px;
    padding-left: 8px;
    font-size: 24px;
  }
}

.side-menu__features {
  @include list-reset;
  position: relative;
  padding: 16px 29px 15px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-whisper;
  }

  @include viewport--sm {
    padding-left: 33px;
    padding-right: 33px;

    &::after {
      display: none;
    }
  }

  @media (max-width: 540px) {
    padding-left: 8px;
    padding-right: 20px;
  }
}

.side-menu__features-item {
  display: flex;
  align-items: center;

  &--best-seller {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          fill: $color-neon-pink;
        }

        a {
          color: $color-neon-pink;
        }
      }
    }
  }

  &--new {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          fill: $color-vista-blue;
        }

        a {
          color: $color-vista-blue;
        }
      }
    }
  }

  &--sale {
    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        svg {
          fill: $color-pumpkin;
        }

        a {
          color: $color-pumpkin;
        }
      }
    }

    svg {
      transform: rotate(90deg);
    }
  }

  svg {
    flex-shrink: 0;
    margin-right: auto;
    fill: $color-night-rider;
    transition: all $transition;
  }

  a {
    width: 91%;
    line-height: 30px;
    font-weight: 600;
    color: $color-night-rider;
    transition: all $transition;
  }

  @include viewport--sm {
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0;
    }

    &--best-seller {
      svg {
        width: 22px;
        height: 32px;
      }
    }

    &--new {
      svg {
        width: 32px;
        height: 30px;
      }
    }

    &--sale {
      svg {
        width: 30px;
        height: 38px;
      }
    }

    a {
      font-size: 22px;
    }
  }

  @media (max-width: 540px) {
    &--best-seller {
      svg {
        width: 16px;
        height: 24px;
      }
    }

    &--new {
      svg {
        width: 24px;
        height: 24px;
      }
    }

    &--sale {
      svg {
        width: 22px;
        height: 28px;
      }
    }

    a {
      padding-left: 10px;
      font-size: 18px;
    }
  }
}

.side-menu__main-category-list {
  @include list-reset;
  padding: 21px 29px 0;

  @media (max-width: 540px) {
    padding-left: 8px;
    padding-right: 20px;
  }
}

.side-menu__main-category-item {
  position: relative;
  margin-bottom: 15px;
  padding-left: 30px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    position: absolute;
    top: 4px;
    left: 4px;
    flex-shrink: 0;
    display: none;
    fill: $color-dim-gray;
    transform: rotate(45deg);
  }

  &--dropdown {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $color-gainsboro;
    }

    svg {
      display: block;
    }
  }

  .side-menu__main-category-title,
  a {
    line-height: 18px;
    color: $color-matterhorn;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-dodger-blue;
      }
    }
  }

  .side-menu__main-category-amount,
  .side-menu__sub-category-amount {
    padding-left: 6px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    color: $color-silver;
  }

  @include viewport--sm {
    margin-bottom: 30px;
    padding-left: 54px;

    &--dropdown {
      &::before {
        width: 30px;
        height: 30px;
      }
    }

    svg {
      top: 7px;
      left: 7px;
      width: 15px;
      height: 15px;
    }

    .side-menu__main-category-title,
    a {
      font-size: 22px;
      line-height: 31px;
    }

    .side-menu__main-category-amount,
    .side-menu__sub-category-amount {
      font-size: 20px;
    }
  }

  @media (max-width: 540px) {
    margin-bottom: 22px;
    padding-left: 30px;

    &--dropdown {
      &::before {
        width: 22px;
        height: 22px;
      }
    }

    svg {
      top: 6px;
      left: 6px;
      width: 10px;
      height: 10px;
    }

    .side-menu__main-category-title,
    a {
      font-size: 18px;
      line-height: 24px;
    }

    .side-menu__main-category-amount,
    .side-menu__sub-category-amount {
      font-size: 16px;
    }
  }
}

.side-menu__sub-category-list {
  @include list-reset;
  display: none;
  padding-top: 10px;
  padding-left: 17px;

  @media (max-width: 540px) {
    padding-left: 10px;
  }
}

.side-menu__sub-category-item {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  @include viewport--sm {
    margin-bottom: 15px;
  }
}

.side-menu__best-seller {
  @include viewport--sm {
    display: none;
  }
}

.side-menu__personal {
  border: 1px solid $color-whisper;

  &--hidden {
    display: none;
  }

  @include viewport--sm {
    border: none;

    svg {
      top: 21px;
      width: 22px;
      height: 26px;
    }

    .side-menu__title {
      padding-left: 70px;
    }
  }
}

.side-menu__personal-title-box {
  position: relative;
  margin-bottom: 30px;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 29px;
  }

  .side-menu__title {
    padding-left: 53px;
  }

  @include viewport--sm {
    svg {
      width: 22px;
      height: 26px;
    }

    .side-menu__title {
      padding-left: 70px;
    }
  }

  @media (max-width: 540px) {
    svg {
      left: 7px;
    }

    .side-menu__title {
      padding-left: 40px;
    }
  }
}

.side-menu__personal-list {
  @include list-reset;
  padding-top: 18px;
  padding-bottom: 30px;
}

.side-menu__personal-item {
  a {
    display: inline-block;
    width: 100%;
    padding-left: 27px;
    line-height: 30px;
    font-weight: 600;
    color: $color-night-rider;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-dodger-blue;
      }
    }
  }

  &--current {
    a {
      color: $color-amaranth;
      background-color: $color-white-smoke;
      border-left: 2px solid $color-amaranth;
    }
  }

  @include viewport--sm {
    margin-bottom: 5px;

    a {
      font-size: 22px;
      line-height: 44px;
    }
  }

  @media (max-width: 540px) {
    a {
      font-size: 18px;
      line-height: 36px;
    }
  }
}
