.best-seller {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 318px;
  margin: 0 auto;
  padding: 33px 30px 59px;
  background-color: $color-silver-tree;

  img {
    width: 158px;
    margin-bottom: 21px;
    object-fit: cover;
  }

  @media (max-width: 900px) {
    width: 280px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include viewport--sm {
    width: 89%;
    padding-top: 62px;
    padding-bottom: 81px;

    img {
      width: 316px;
      margin-bottom: 46px;
    }
  }

  @media (max-width: 440px) {
    padding-top: 40px;
    padding-bottom: 40px;

    img {
      width: 250px;
      margin-bottom: 30px;
    }
  }
}

.best-seller__title {
  display: flex;
  align-items: center;
  margin: 0;
  margin-bottom: 26px;
  font-size: 15px;
  line-height: 24px;
  color: $color-stromboli;

  svg {
    margin-right: 8px;
    fill: $color-stromboli;
  }

  @include viewport--sm {
    width: 100%;
    max-width: 340px;
    margin-bottom: 40px;
    font-size: 26px;
    line-height: 1;

    svg {
      width: 34px;
      height: 50px;
      margin-right: 25px;
    }

    span {
      padding-top: 15px;
    }
  }

  @media (max-width: 440px) {
    justify-content: center;
    max-width: 275px;
    font-size: 22px;

    svg {
      width: 25px;
      height: 36px;
      margin-right: 15px;
    }
  }
}

.best-seller__author {
  margin: 0;
  font-size: 18px;
  line-height: 30px;
  font-weight: 800;
  color: $color-white;

  @include viewport--sm {
    margin-bottom: 10px;
    font-size: 20px;
  }
}

.best-seller__name {
  max-width: 270px;
  margin-bottom: 20px;
  line-height: 20px;
  font-weight: 300;
  color: $color-white;
  text-align: center;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-stromboli;
    }
  }

  @include viewport--sm {
    max-width: 425px;
    margin-bottom: 40px;
    font-size: 21px;
    line-height: 30px;
  }

  @media (max-width: 440px) {
    margin-bottom: 30px;
  }
}

.best-seller__offer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 258px;
  padding-right: 11px;
  padding-left: 11px;

  @include viewport--sm {
    width: 400px;
  }

  @media (max-width: 440px) {
    flex-direction: column;
    justify-content: center;
    width: auto;
  }
}

.best-seller__price {
  margin-bottom: 10px;
  margin-right: auto;
  padding-right: 5px;
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  color: $color-white;

  @include viewport--sm {
    font-size: 30px;
  }

  @media (max-width: 440px) {
    margin-right: 0;
    margin-bottom: 15px;
    padding-right: 0;
    font-size: 22px;
  }
}

.best-seller__order {
  margin-bottom: 10px;
}
