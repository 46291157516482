.table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  thead tr,
  tbody tr {
    border-bottom: 1px solid $color-gainsboro;
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  thead th,
  tbody td {
    padding: 11px 7px 15px 18px;
    border-right: 1px solid $color-gainsboro;
  }

  thead th:last-child,
  tbody td:last-child {
    border-right: none;
  }

  th {
    font-size: 15px;
    line-height: 22px;
    font-weight: 700;
    text-align: left;
  }

  td {
    position: relative;
    font-size: 13px;
    line-height: 24px;
    vertical-align: top;

    svg {
      position: absolute;
      left: 10px;
      top: 10px;
      fill: $color-amaranth;
    }
  }

  .table__td-svg {
    padding-left: 46px;
  }

  @include viewport--md {
    table-layout: auto;

    thead th,
    tbody td {
      padding-top: 17px;
      padding-bottom: 19px;
      padding-left: 20px;
    }

    th {
      font-size: 20px;
      line-height: 30px;
    }

    td {
      font-size: 21px;
      line-height: 30px;

      svg {
        top: 17px;
      }
    }
  }

  @media (max-width: 440px) {
    th,
    td {
      font-size: 18px;
    }
  }
}
