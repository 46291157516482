.order-info__head {
  margin-bottom: 15px;

  @include viewport--sm {
    margin-bottom: 50px;
    padding-left: 23px;
    padding-right: 23px;
  }

  @media (max-width: 440px) {
    margin-bottom: 35px;
  }
}

.order-info__label {
  margin: 0;
  margin-bottom: 13px;
  line-height: 30px;

  @include viewport--sm {
    margin-bottom: 18px;
    font-size: 31px;
    line-height: 40px;
  }

  @media (max-width: 440px) {
    margin-bottom: 15px;
    font-size: 24px;
  }
}

.order-info__controls {
  display: flex;
  flex-wrap: wrap;
}

.order-info__control {
  margin-right: 20px;
  font-size: 20px;
  line-height: 40px;
  font-weight: 300;
  color: $color-black;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover {
      color: lighten($color-black, 40%);
    }
  }

  &:last-child {
    margin-right: 0;
  }

  &--current {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 7px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $color-black;
    }
  }

  @include viewport--sm {
    margin-right: 48px;
    font-size: 27px;
    line-height: 60px;

    &--current {
      &::after {
        bottom: 10px;
      }
    }
  }

  @media (max-width: 440px) {
    margin-right: 30px;
    font-size: 22px;
    line-height: 45px;

    &--current {
      &::after {
        bottom: 5px;
      }
    }
  }
}

.order-info__showcase-item {
  &--hidden {
    display: none;
  }

  p {
    margin: 0;
    margin-bottom: 11px;
    font-size: 15px;
    line-height: 24px;
  }

  .order-info__order-list {
    @include list-reset;
    margin-bottom: 25px;
    font-size: 13px;
    line-height: 25px;

    li {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        display: inline-block;
        margin-right: 7px;
        font-weight: 700;
      }
    }
  }

  .order-info__text-semibold {
    margin-bottom: 4px;
    font-weight: 600;
  }

  .order-info__list-attension {
    @include list-reset;
    margin-bottom: 3px;
    font-size: 13px;
    line-height: 25px;
  }

  .order-info__item-attension {
    position: relative;
    padding-left: 30px;

    svg {
      position: absolute;
      left: 2px;
      top: 3px;
      fill: $color-amaranth;
    }

    span {
      color: $color-amaranth;
    }
  }

  .order-info__note {
    margin-bottom: 10px;
    padding-left: 30px;
    font-size: 13px;
    line-height: 22px;

    span {
      color: $color-amaranth;
    }
  }

  .order-info__important {
    margin-top: 38px;
    margin-bottom: 38px;
  }

  h3 {
    margin: 0;
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 36px;
    font-weight: 800;
  }

  .order-info__table-payment {
    margin-top: 10px;
    margin-bottom: 50px;

    th {
      &:nth-child(1) {
        width: 24%;
      }

      &:nth-child(2) {
        width: 28%;
      }
    }
  }

  ol {
    @include list-reset;
    padding-left: 13px;
    list-style-type: decimal;
    line-height: 36px;
    font-weight: 600;

    li {
      margin-bottom: 7px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .order-info__sub-list {
    padding-left: 10px;
  }

  .order-info__delivery-method {
    margin-bottom: 25px;

    span {
      font-weight: 600;
    }
  }

  .order-info__dispatch-method {
    margin-bottom: 40px;
    padding-left: 25px;

    li {
      margin-bottom: 5px;
    }
  }

  .order-info__delivery-info {
    margin-bottom: 30px;
  }

  a {
    color: $color-dodger-blue;
    transition: all $transition;

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        color: $color-brick-red;
      }
    }
  }

  .order-info__discount-calc {
    margin-top: 35px;
    margin-bottom: 25px;

    h3 {
      margin-bottom: 20px;
    }

    .order-info__table-discount {
      width: 47%;

      th {
        &:nth-child(1) {
          width: 69%;
        }

        &:nth-child(2) {
          text-align: center;
        }
      }

      td {
        padding-bottom: 10px;
        padding-top: 10px;

        &:nth-child(2) {
          text-align: center;
        }
      }
    }
  }

  @include viewport--sm {
    p {
      margin-bottom: 15px;
      padding-left: 23px;
      padding-right: 23px;
      font-size: 21px;
      line-height: 35px;
    }

    .order-info__order-list {
      padding-left: 23px;
      padding-right: 23px;
      font-size: 21px;
      line-height: 36px;

      li {
        margin-bottom: 15px;
      }
    }

    .order-info__list-attension {
      margin-bottom: 15px;
      padding-left: 23px;
      padding-right: 23px;
      font-size: 21px;
      line-height: 36px;
    }

    .order-info__item-attension {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        top: 10px;
      }
    }

    .order-info__note {
      margin-bottom: 15px;
      padding-left: 23px;
      font-size: 21px;
      line-height: 36px;
    }

    h3 {
      margin-bottom: 18px;
      padding-left: 23px;
      padding-right: 23px;
      font-size: 25px;
    }

    .order-info__table-wrapper {
      padding-left: 12px;
      overflow-x: auto;
    }

    .order-info__table-payment {
      width: 200%;
      margin-top: 27px;
      margin-bottom: 70px;

      th {
        &:nth-child(1) {
          width: 23.5%;
        }

        &:nth-child(2) {
          width: 24%;
        }
      }
    }

    ol {
      margin-top: 42px;
      margin-bottom: 15px;
      padding-left: 43px;
      padding-right: 23px;
      font-size: 21px;

      li {
        margin-bottom: 30px;
      }
    }

    .order-info__sub-list {
      padding-left: 4px;

      li {
        margin-bottom: 5px;
      }
    }

    .order-info__dispatch-method {
      padding-left: 44px;
      padding-right: 23px;

      li {
        margin-bottom: 15px;
        padding-left: 0;
      }
    }

    .order-info__delivery-info {
      .order-info__item-attension {
        padding-left: 50px;

        svg {
          left: 23px;
        }
      }
    }

    .order-info__discount-calc {
      padding-left: 23px;
      padding-right: 23px;

      .order-info__table-discount {
        width: 100%;
      }
    }
  }

  @media (max-width: 440px) {
    p {
      font-size: 18px;
      line-height: 30px;
    }

    .order-info__order-list,
    .order-info__list-attension {
      font-size: 18px;
      line-height: 30px;
    }

    .order-info__item-attension {
      svg {
        top: 6px;
      }
    }

    .order-info__note {
      font-size: 18px;
      line-height: 30px;
    }

    h3 {
      font-size: 22px;
    }

    .order-info__table-payment {
      width: 150%;
      margin-top: 15px;
      margin-bottom: 30px;

      th {
        &:nth-child(2) {
          width: 28%;
        }
      }
    }

    ol {
      margin-top: 25px;
      font-size: 18px;
      line-height: 30px;

      li {
        margin-bottom: 15px;
      }
    }

    .order-info__sub-list {
      font-size: 18px;
      line-height: 30px;
    }

    .order-info__dispatch-method {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 30px;
    }

    .order-info__discount-calc {
      h3 {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
