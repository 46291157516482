@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat Thin"),
    local("Montserrat-Thin"),
    url("../fonts/montserrat/thin/montserrat-100.woff2") format("woff2"),
    url("../fonts/montserrat/thin/montserrat-100.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat Light"),
    local("Montserrat-Light"),
    url("../fonts/montserrat/light/montserrat-300.woff2") format("woff2"),
    url("../fonts/montserrat/light/montserrat-300.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat Regular"),
    local("Montserrat-Regular"),
    url("../fonts/montserrat/regular/montserrat-400.woff2") format("woff2"),
    url("../fonts/montserrat/regular/montserrat-400.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat SemiBold"),
    local("Montserrat-SemiBold"),
    url("../fonts/montserrat/semibold/montserrat-600.woff2") format("woff2"),
    url("../fonts/montserrat/semibold/montserrat-600.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat Bold"),
    local("Montserrat-Bold"),
    url("../fonts/montserrat/bold/montserrat-700.woff2") format("woff2"),
    url("../fonts/montserrat/bold/montserrat-700.woff") format("woff");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: local("Montserrat ExtraBold"),
    local("Montserrat-ExtraBold"),
    url("../fonts/montserrat/extrabold/montserrat-800.woff2") format("woff2"),
    url("../fonts/montserrat/extrabold/montserrat-800.woff") format("woff");
}
