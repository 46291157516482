.main {
  @include viewport--sm {
    position: relative;
  }
}

.main__wrapper {
  @include container;
  display: grid;
  grid-template-columns: 374px 1fr;
  grid-column-gap: 25px;
  padding-top: 25px;
  padding-bottom: 43px;

  @media (max-width: 1140px) {
    grid-template-columns: 330px 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 300px 1fr;
  }

  @include viewport--sm {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 37px;
  }
}

.main__button-menu {
  display: none;

  @include viewport--sm {
    display: block;
    margin-top: 395px;
    margin-bottom: 43px;
    padding-left: 23px;
    padding-right: 23px;

    &--hide {
      display: none;
    }

    &--mt {
      margin-top: 0;
      margin-bottom: 23px;
    }
  }
}

.main__content {
  &--hide {
    display: none;
  }

  @media (max-width: 991px) {
    &--payment {
      overflow-x: auto;
    }
  }
}

.main__best-seller {
  display: none;

  @include viewport--sm {
    display: flex;
    margin-top: 128px;
  }

  @media (max-width: 440px) {
    margin-top: 60px;
  }
}
