body {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  font-family: $font-family--proxima-nova;
  color: $color-black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}
