.card-list {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  padding-left: 5px;
  padding-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-gainsboro;
  }

  @media (max-width: 940px) {
    &--search {
      flex-direction: column;

      .card-list__left {
        margin-bottom: 10px;
      }

      .card-list__right {
        align-items: flex-start;
      }

      .card-list__status {
        text-align: left;
      }
    }
  }

  @include viewport--sm {
    padding-left: 23px;
    padding-right: 23px;

    &--search {
      .card-list__left {
        flex-direction: column;
        align-items: flex-start;
      }

      .card-list__image {
        margin-bottom: 10px;
      }

      .card-list__price-list {
        margin-bottom: 10px;
      }
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.card-list__left {
  display: flex;

  @media (max-width: 650px) {
    margin-bottom: 30px;
  }

  @media (max-width: 440px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.card-list__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 386px;
  width: 100%;

  @media (max-width: 1240px) {
    flex-direction: column;
    align-items: flex-end;
    max-width: 100%;
    width: auto;
  }

  @media (max-width: 650px) {
    align-items: flex-start;
    justify-content: normal;
  }
}

.card-list__image {
  position: relative;
  margin-right: 26px;

  img {
    width: 87px;
    object-fit: cover;
  }

  .card-list__flags {
    top: -1px;
    right: -6px;
  }

  @include viewport--md {
    margin-right: 16px;
  }

  @include viewport--sm {
    img {
      width: 108px;
    }
  }

  @media (max-width: 650px) {
    margin-right: 30px;
  }

  @media (max-width: 440px) {
    margin-right: 0;
    margin-bottom: 15px;

    img {
      width: 150px;
    }
  }
}

.card-list__author,
.card-list__title,
.card-list__text,
.card-list__publishing,
.card-list__isbn,
.card-list__ean {
  margin: 0;
}

.card-list__author {
  margin-bottom: 4px;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;

  @include viewport--sm {
    font-size: 21px;
  }
}

.card-list__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  height: 24px;
  overflow: hidden;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  text-overflow: ellipsis;

  @include viewport--sm {
    font-size: 17px;
    line-height: 30px;
  }
}

.card-list__text {
  margin-bottom: 5px;
  line-height: 16px;

  @include viewport--sm {
    font-size: 15px;
    line-height: 24px;
  }
}

.card-list__info-caption {
  color: $color-grey;
}

.card-list__link {
  color: $color-havelock-blue;
  transition: all $transition;

  @media (hover: hover), screen and (min-width: 0\0) {
    &:hover,
    &:focus {
      color: $color-amaranth;
    }
  }
}

.card-list__status {
  width: 120px;
  padding-bottom: 10px;

  @media (max-width: 1240px) {
    padding-bottom: 0;
    text-align: right;
  }

  @include viewport--sm {
    width: 150px;
  }

  @media (max-width: 650px) {
    margin-bottom: 10px;
    text-align: left;
  }
}

.card-list__status-product {
  font-size: 12px;
  line-height: 24px;

  @include viewport--sm {
    font-size: 15px;
  }
}

.card-list__price-list {
  display: flex;
  flex-direction: column;
  padding-bottom: 14px;

  @media (max-width: 1240px) {
    padding-bottom: 0;
  }

  @media (max-width: 650px) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }
}

.card-list__current-price {
  font-size: 17px;
  line-height: 36px;
  font-weight: 800;

  @include viewport--sm {
    font-size: 24px;
  }

  @media (max-width: 650px) {
    margin-right: 15px;
  }
}

.card-list__old-price {
  font-size: 15px;
  text-decoration: line-through;
  color: $color-dim-gray;
}

.card-list__order {
  padding-bottom: 10px;

  @media (max-width: 1240px) {
    padding-bottom: 0;
  }
}
